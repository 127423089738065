#more-about-me {
  text-align: center;
  padding: 50px 0;
  background-color: #2B2B2B; /* Update background color to a lighter shade */
}

.section-title {
  font: 48px/56px 'opensans-bold', sans-serif; /* Increase font size and match the theme font */
  color: #fff; /* Lighter color for better readability */
  margin: 20px 0; /* Add top and bottom margin */
  padding: 20px 0; /* Add padding on top and bottom */
  text-transform: uppercase; /* Make the text uppercase */
  letter-spacing: 2px; /* Add letter spacing for better readability */
  position: relative; /* Position relative for the pseudo-element */
  display: inline-block; /* Ensure the title is inline-block to fit the text width */
}

.section-title::after {
  content: '';
  display: inline-block;
  width: 100%; /* Underline spans the width of the text */
  height: 3px; /* Height of the underline */
  background-color: #19b6ff; /* Color of the underline */
  position: absolute;
  bottom: -5px; /* Position slightly below the text */
  left: 0; /* Align with the start of the text */
}

.slider {
  /* keeps the same idea: acts as a "window" */
  overflow: hidden;
  position: relative;
  width: 100%; /* or whatever your container width is */
  max-width: 800px;
  margin: 0 auto;
}

.slides-container {
  display: flex;              /* or inline-block approach is also possible */
  width: 300%;                /* if you have exactly 3 slides; or dynamic */
  transition: margin-left 0.5s ease-in-out; 
  margin-left: 0;            /* we’ll change this from JS */
}

.slide {
  width: 100%;  /* each slide is exactly the viewport/container width */
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  padding: 20px; /* Add padding for consistent spacing */
  position: relative;
  overflow: hidden;
  /* no transform needed! */
}

.slide img {
  width: auto; /* Allow images to scale */
  max-width: 100%;
  max-height: 400px; /* Set a maximum height for images */
  height: auto;
  margin-bottom: 20px;
  object-fit: contain; /* Ensure images fit within the container */
}

.slide h2 {
  font: 36px/44px 'opensans-bold', sans-serif; /* Increase font size and match the theme font */
  color: #fff; /* Lighter color for better readability */
  margin-bottom: 20px; /* Increase bottom margin for better spacing */
  text-transform: uppercase; /* Make the text uppercase */
  letter-spacing: 2px; /* Add letter spacing for better readability */
}

.slide p {
  font: 18px/24px 'opensans-regular', sans-serif; /* Match the theme font */
  color: #A8A8A8; /* Lighter color for better readability */
  line-height: 1.6;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 34px;
  z-index: 10; /* Ensure arrows are in front of slide content */
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.slide .rsm-geography {
  cursor: pointer;
}

.map-container {
  position: relative;
  width: 100%;
  height: 600px; /* Larger default height */
  min-height: 400px; /* Minimum height to ensure it's not too small */
  overflow: hidden; /* Hide overflow to prevent map from spilling over */
  border:rgba(0, 0, 0, 0.3) solid 5px; /* Add border for visual separation */
  background: #1b1b1b;
}

.map-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: grab; /* Indicate draggable area */
}

.map-inner:active {
  cursor: grabbing; /* Change cursor when dragging */
}

.blurred {
  filter: blur(8px);
  pointer-events: none; /* Disable interactions while blurred */
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  z-index: 5; /* Ensure overlay is above the map */
}

.blur-message {
  font-size: 18px;
  margin-bottom: 20px;
}

.unblur-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.unblur-button:hover {
  background-color: #0056b3;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 80%;
  max-width: 500px;
  max-height: 80vh; /* Ensure the popup does not exceed 80% of the viewport height */
  overflow-y: auto; /* Add vertical scroll if content exceeds max-height */
  border-radius: 10px;
}

.popup-content h2 {
  font-family: 'librebaskerville-bold', serif; /* Use Libre Baskerville Bold for the title */
  font-size: 30px;
  color: #333;
  margin-bottom: 20px;
  position: relative; /* Position relative for the pseudo-element */
  display: inline-block; /* Ensure the title is inline-block to fit the text width */
}

.popup-content h2::after {
  content: '';
  display: inline-block;
  width: 100%; /* Underline spans the width of the text */
  height: 3px; /* Height of the underline */
  background-color: #000000; /* Color of the underline */
  position: absolute;
  bottom: -6px; /* Position slightly below the text */
  left: 0; /* Align with the start of the text */
}

.popup-content p {
  font-family: 'Lora', serif;/* Ensure the paragraph uses the regular font */
  color: #333;
}

.popup-content p strong {
  font-family: 'Lora', serif; /* Use Roboto for the title */
  font-weight: bold; /* Bold the text */
}

.popup-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.popup-content img {
  width: 100%; /* Ensure the image takes the full width of the container */
  height: auto; /* Maintain aspect ratio */
  max-height: 350px; /* Set a maximum height for the image */
  object-fit: cover; /* Ensure the image covers the container without distortion */
  margin-top: 10px;
  border-radius: 5px;
  border: 2px solid #000000; /* Add a border around the image */
}

.popup-content .caption {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
  text-align: center;
  font-style: italic; /* Italicize the caption */
}

.slide .caption {
  font-size: 14px;
  color: #A8A8A8;
  margin-top: 5px;
  text-align: center;
  font-style: italic;
}


.video-editing-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.video-editing-background {
  position: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Change to column to stack gifs vertically */
  overflow: hidden;
}

.video-gif {
  width: 100%;
  height: 33.33%; /* Each gif takes up a third of the height */
  object-fit: cover;
}

.video-editing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.video-editing-overlay h2 {
  font: 36px/44px 'opensans-bold', sans-serif;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.video-editing-overlay p {
  font: 18px/24px 'opensans-regular', sans-serif;
  color: #A8A8A8;
  line-height: 1.6;
}

.philosophy-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000; /* Full black background */
  color: #fff; /* White text for contrast */
  padding: 20px;
  text-align: center;
  position: relative; /* For positioning question marks */
  height: 100vh; /* Full height */
}

.philosophy-background {
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  z-index: 1; /* Ensure it is above the question marks */
}

.philosophy-caption {
  font-size: 12px !important;
  margin-bottom: 10px;
  font-style: italic !important;
  color: #ccc; /* Slightly lighter color for the caption */
}

.philosophy-message {
  font-size: 24px;
  margin-bottom: 20px;
}

.philosophy-input {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
}

.philosophy-submit {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #333; /* Dark background for the button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.philosophy-submit:hover {
  background-color: #555; /* Slightly lighter background on hover */
}

.question-marks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0; /* Behind the content */
}

.question-mark {
  position: absolute;
  color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  animation: fadeInOut 3s infinite;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@media (max-width: 768px) {
  .map-container {
    height: 500px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 480px) {
  .map-container {
    height: 400px; /* Further adjust height for very small screens */
  }
}